import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface FtpExportState {
    ftpExport: FtpExport | null;
    ftpExports: FtpExports | null;
    ftpExportOptions: FtpExports | null;
}

export const ftpExport = merge(getBaseStore('ftpExport'), {
    actions: {
        async createFtpExport({ commit, state }: ActionContext<FtpExportState, RootState>) {
            commit('updateFtpExport', {
                enabled: false,
                ftp_port: 21,
                statuses: ['scheduled', 'terminated', 'missed', 'cancelled', 'cancelled_by_consultant'],
                file_name_pattern: 'automatic_export_[date].csv',
                column_separator: ',',
                multiple_values_separator: '|',
                export_type: 'diff',
                recurrence_rule: '30 23 * * *',
                exported_columns: [],
                meetings: [],
                period_value: 1,
                periodic_export_criteria: 'date',
                period_type: 'week',
                valid_from: null,
            });

            return state.ftpExport;
        },
    },
});
