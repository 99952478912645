import loader from '@/composables/loader';
import { getEnv } from '@/env/env';
import { editBeforeRouteEnter } from '@/router/hook-utils';
import Store from '@/store';
import { onImportError } from '@/utils/onImportError';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { camelCase, upperFirst } from 'lodash';
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const isInMaintenance = getEnv().maintenance;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/views/Maintenance.vue').catch(async () => onImportError()),
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/auth/Logout.vue').catch(async () => onImportError()),
    },
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/auth/Login.vue').catch(async () => onImportError()),
        beforeEnter: async (to, from, next) => {
            if (to.query.autologin) {
                try {
                    await Store.dispatch('user/getExternalAuthUrl', to.query.autologin);
                } catch (e) {
                    next();
                }
            } else {
                next();
            }
        },
    },
    {
        path: '/auth-google',
        name: 'auth-google',
        component: () => import('@/views/auth/Login.vue').catch(async () => onImportError()),
        beforeEnter: async (to, from, next) => {
            try {
                if (Capacitor.isNativePlatform()) await Browser.close();
            } catch (e) {
                console.error(e);
            }
            const isOkExternal = await Store.dispatch('user/externalLogin', to);
            return isOkExternal ? next('/main/dashboard') : next('/');
        },
        meta: {
            provider: 'google',
        },
    },
    {
        path: '/auth-microsoft',
        name: 'auth-microsoft',
        component: () => import('@/views/auth/Login.vue').catch(async () => onImportError()),
        beforeEnter: async (to, from, next) => {
            try {
                if (Capacitor.isNativePlatform()) await Browser.close();
            } catch (e) {
                console.error(e);
            }
            const isOkExternal = await Store.dispatch('user/externalLogin', to);
            return isOkExternal ? next('/main/dashboard') : next('/');
        },
        meta: {
            provider: 'microsoft',
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/auth/ResetPassword.vue').catch(async () => onImportError()),
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import('@/views/auth/ChangePassword.vue').catch(async () => onImportError()),
        beforeEnter: async (to, from, next) => {
            return to.query['loginToken'] && to.query['mail'] ? next() : next('/');
        },
    },
    {
        path: '/main/',
        component: () => import('@/views/Tabs.vue').catch(async () => onImportError()),
        children: [
            {
                name: 'dashboard',
                path: 'dashboard',
                component: () => import('@/views/Dashboard.vue').catch(async () => onImportError()),
                beforeEnter: async (to, from, next) => {
                    if (!Store.getters['user/hasApendayBookEnabled']) {
                        return next({ name: Store.getters['user/hasApendayLiveEnabled'] ? 'live' : 'session' });
                    }
                    next();
                },
            },
            // Meeting
            {
                name: 'meeting-dashboard',
                path: 'meeting',
                component: () => import('@/views/meeting/Dashboard.vue').catch(async () => onImportError()),
            },
            {
                name: 'meeting-list',
                path: 'meeting/list',
                component: () => import('@/views/meeting/List.vue').catch(async () => onImportError()),
            },
            {
                path: 'meeting/create/',
                component: () => import('@/views/meeting/Edit.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'meeting-create-one-to-one',
                        path: 'one-to-one',
                        component: () => import('@/views/meeting/edit/config/Configuration.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'configuration',
                            meetingMode: 'one_to_one',
                        },
                    },
                    {
                        name: 'meeting-create-team',
                        path: 'team',
                        component: () => import('@/views/meeting/edit/config/Configuration.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'configuration',
                            meetingMode: 'team',
                        },
                    },
                    {
                        name: 'meeting-create-group',
                        path: 'group',
                        component: () => import('@/views/meeting/edit/config/Configuration.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'configuration',
                            meetingMode: 'group',
                        },
                    },
                ],
            },
            {
                path: 'meeting/edit/',
                component: () => import('@/views/meeting/Edit.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'meeting-edit',
                        path: 'configuration/:id',
                        component: () => import('@/views/meeting/edit/config/Configuration.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'configuration',
                        },
                    },
                    {
                        name: 'meeting-edit-dynamic-fields',
                        path: 'dynamic-fields/:id',
                        component: () => import('@/views/meeting/edit/dynamicFields/DynamicFields.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'dynamicFields',
                        },
                    },
                    {
                        name: 'meeting-edit-team',
                        path: 'team/:id',
                        component: () => import('@/views/meeting/edit/team/Team.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'team',
                        },
                    },
                    {
                        name: 'meeting-edit-notifications',
                        path: 'notifications/:id',
                        component: () => import('@/views/meeting/edit/notifications/Notifications.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'notifications',
                        },
                    },
                    {
                        name: 'meeting-edit-restrictions',
                        path: 'restrictions/:id',
                        component: () => import('@/views/meeting/edit/restrictions/Restrictions.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'restrictions',
                        },
                    },
                    {
                        name: 'meeting-edit-satisfaction',
                        path: 'satisfaction/:id?',
                        component: () => import('@/views/meeting/edit/satisfaction/Satisfaction.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'satisfaction',
                        },
                    },
                    {
                        name: 'meeting-edit-crm',
                        path: 'crm/:id?',
                        component: () => import('@/views/crm/Crm.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'crm',
                        },
                    },
                    {
                        name: 'meeting-edit-crm-configure',
                        path: 'crm/:id?/configure/:crmId',
                        component: () => import('@/views/crm/configure/Configure.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting',
                            segment: 'crm',
                        },
                    },
                ],
            },
            {
                path: 'meeting/publish/',
                component: () => import('@/views/meeting/Publish.vue').catch(async () => onImportError()),
                beforeEnter: editBeforeRouteEnter,
                meta: { entityName: 'meeting' },
                children: [
                    {
                        name: 'meeting-publish',
                        path: 'informations/:id',
                        component: () => import('@/views/meeting/publish/informations/Informations.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting-publish',
                            segment: 'informations',
                        },
                    },
                    {
                        name: 'meeting-publish-integration',
                        path: 'integration/:id',
                        component: () => import('@/views/meeting/publish/integration/Integration.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'meeting-publish',
                            segment: 'integration',
                        },
                    },
                    {
                        name: 'meeting-publish-invite',
                        path: 'invite/:id',
                        component: () => import('@/views/meeting/publish/invite/Invite.vue'),
                        meta: {
                            groupId: 'meeting-publish',
                            segment: 'invite',
                        },
                        async beforeEnter() {
                            if (Store.state.meeting.meeting.enabled) {
                                await Store.dispatch('invite/createInvite');
                                return true;
                            } else {
                                return {
                                    name: 'meeting-list',
                                };
                            }
                        },
                    },
                    {
                        name: 'meeting-publish-schedule',
                        path: 'schedule/:id',
                        component: () => import('@/views/meeting/publish/schedule/Schedule.vue'),
                        meta: {
                            groupId: 'meeting-publish',
                            segment: 'schedule',
                        },
                        async beforeEnter() {
                            if (Store.state.meeting.meeting.enabled) {
                                await Store.dispatch('scheduledMeeting/createScheduledMeeting');
                                return true;
                            } else {
                                return {
                                    name: 'meeting-list',
                                };
                            }
                        },
                    },
                ],
            },
            {
                name: 'hub-list',
                path: 'meeting/hub/list',
                component: () => import('@/views/hub/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'hub-create',
                path: 'meeting/hub/edit',
                component: () => import('@/views/hub/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'hub' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'hub-edit',
                path: 'meeting/hub/edit/:id',
                component: () => import('@/views/hub/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'hub' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                path: 'meeting/hub/publish/',
                component: () => import('@/views/hub/Publish.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'hub-publish',
                        path: 'informations/:id',
                        component: () => import('@/views/hub/publish/informations/Informations.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'hub-publish',
                            segment: 'informations',
                        },
                    },
                    {
                        name: 'hub-publish-iframe',
                        path: 'iframe/:id',
                        component: () => import('@/views/hub/publish/iframe/Iframe.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'hub-publish',
                            segment: 'iframe',
                        },
                    },
                    {
                        name: 'hub-publish-link',
                        path: 'link/:id',
                        component: () => import('@/views/hub/publish/link/Link.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'hub-publish',
                            segment: 'link',
                        },
                    },
                    {
                        name: 'hub-publish-pop-in',
                        path: 'pop-in/:id',
                        component: () => import('@/views/hub/publish/popin/Popin.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'hub-publish',
                            segment: 'popIn',
                        },
                    },
                ],
            },
            // Scheduled Meeting
            {
                name: 'scheduled-meeting-forthcoming',
                path: 'meeting/scheduled-meeting/forthcoming',
                component: () => import('@/views/scheduledMeeting/List.vue').catch(async () => onImportError()),
                meta: {
                    pageTitle: 'scheduledMeeting.forthcoming.pageTitle',
                    type: 'forthcoming',
                },
            },
            {
                name: 'scheduled-meeting-past',
                path: 'meeting/scheduled-meeting/past',
                component: () => import('@/views/scheduledMeeting/List.vue').catch(async () => onImportError()),
                meta: {
                    pageTitle: 'scheduledMeeting.past.pageTitle',
                    type: 'past',
                },
            },
            {
                name: 'scheduled-meeting-history',
                path: 'meeting/scheduled-meeting/history',
                component: () => import('@/views/scheduledMeeting/List.vue').catch(async () => onImportError()),
                meta: {
                    pageTitle: 'scheduledMeeting.history.pageTitle',
                    type: 'history',
                },
            },
            {
                name: 'scheduled-meeting-bulk-reassign',
                path: 'meeting/scheduled-meeting/bulk-reassign',
                component: () => import('@/views/scheduledMeeting/BulkReassign.vue').catch(async () => onImportError()),
            },
            {
                name: 'scheduled-meeting-edit',
                path: 'meeting/scheduled-meeting/edit/:id',
                component: () => import('@/views/scheduledMeeting/Edit.vue').catch(async () => onImportError()),
            },
            // Configuration
            {
                path: 'config',
                redirect: '/main/config/offer/list',
            },
            {
                name: 'offer-list',
                path: 'config/offer/list',
                component: () => import('@/views/offer/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'offer-create',
                path: 'config/offer/edit',
                component: () => import('@/views/offer/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'offer' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'offer-edit',
                path: 'config/offer/edit/:id',
                component: () => import('@/views/offer/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'offer' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'data-source-list',
                path: 'config/data-source/list',
                component: () => import('@/views/dataSource/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'data-source-create',
                path: 'config/data-source/edit',
                component: () => import('@/views/dataSource/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'dataSource' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'data-source-edit',
                path: 'config/data-source/edit/:id',
                component: () => import('@/views/dataSource/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'dataSource' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'area-list',
                path: 'config/area/list',
                component: () => import('@/views/area/List.vue').catch(async () => onImportError()),
                async beforeEnter() {
                    if (Store.getters['user/isGranted']['admin'] || Store.getters['user/isMultiAreas']) {
                        return true;
                    } else {
                        return {
                            name: 'area-edit-notifications',
                            params: {
                                id: Store.state.user.me.areas?.[0].id,
                            },
                        };
                    }
                },
            },
            {
                path: 'config/area/edit',
                component: () => import('@/views/area/Edit.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'area-create',
                        path: 'general',
                        component: () => import('@/views/area/general/General.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'general',
                        },
                    },
                    {
                        name: 'area-edit',
                        path: 'general/:id',
                        component: () => import('@/views/area/general/General.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'general',
                        },
                    },
                    {
                        name: 'area-edit-appearance',
                        path: 'appearance/:id',
                        component: () => import('@/views/area/appearance/Appearance.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'appearance',
                        },
                    },
                    {
                        name: 'area-edit-technical',
                        path: 'technical/:id',
                        component: () => import('@/views/area/technical/Technical.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'technical',
                        },
                    },
                    {
                        name: 'area-edit-ftp-export-create',
                        path: 'technical/:id/ftp-export/create',
                        component: () => import('@/views/area/technical/FtpExportEdit.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'technical',
                        },
                    },
                    {
                        name: 'area-edit-ftp-export-edit',
                        path: 'technical/:id/ftp-export/:ftpExportId',
                        component: () => import('@/views/area/technical/FtpExportEdit.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'technical',
                        },
                    },
                    {
                        name: 'area-edit-notifications',
                        path: 'notifications/:id',
                        component: () => import('@/views/area/notifications/Notifications.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'notifications',
                        },
                    },
                    {
                        name: 'area-edit-advanced',
                        path: 'advanced/:id',
                        component: () => import('@/views/area/advanced/Advanced.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'advanced',
                        },
                    },
                    {
                        name: 'area-edit-crm',
                        path: 'crm/:id?',
                        component: () => import('@/views/crm/Crm.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'crm',
                        },
                    },
                    {
                        name: 'area-edit-crm-configure',
                        path: 'crm/:id?/configure/:crmId',
                        component: () => import('@/views/crm/configure/Configure.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'area',
                            segment: 'crm',
                        },
                    },
                ],
            },
            {
                name: 'global-config-list',
                path: 'config/globalConfig/list',
                component: () => import('@/views/globalConfig/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'global-config-edit',
                path: 'config/globalConfig/edit/:id',
                component: () => import('@/views/globalConfig/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'globalConfig' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'constraint-list',
                path: 'config/constraint/list',
                component: () => import('@/views/constraint/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'constraint-create',
                path: 'config/constraint/edit',
                component: () => import('@/views/constraint/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'constraint' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'constraint-edit',
                path: 'config/constraint/edit/:id',
                component: () => import('@/views/constraint/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'constraint' },
                beforeEnter: editBeforeRouteEnter,
            },
            // Page
            {
                name: 'page-list',
                path: 'page/list',
                component: () => import('@/views/page/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'page-create',
                path: 'page/edit',
                component: () => import('@/views/page/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'page' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'page-edit',
                path: 'page/edit/:id',
                component: () => import('@/views/page/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'page' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'page-show',
                path: 'page/show/:id',
                component: () => import('@/views/page/Show.vue').catch(async () => onImportError()),
                meta: { entityName: 'page' },
                beforeEnter: editBeforeRouteEnter,
            },
            // Settings
            {
                path: 'settings',
                redirect: '/main/settings/user/list',
            },
            {
                name: 'user-list',
                path: 'settings/user/list',
                component: () => import('@/views/user/List.vue').catch(async () => onImportError()),
            },
            {
                path: 'settings/user/create/',
                component: () => import('@/views/user/Edit.vue').catch(async () => onImportError()),
                beforeEnter: async (routeLocation) => {
                    return await editBeforeRouteEnter(routeLocation, {
                        entityName: 'user',
                        payload: {
                            routeLocation,
                        },
                    });
                },
                children: [
                    {
                        name: 'user-create-office',
                        path: 'microsoft',
                        component: () => import('@/views/user/general/General.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'general',
                            accountType: 'microsoft',
                        },
                    },
                    {
                        name: 'user-create-google',
                        path: 'google',
                        component: () => import('@/views/user/general/General.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'general',
                            accountType: 'google',
                        },
                    },
                    {
                        name: 'user-create-internal',
                        path: 'native',
                        component: () => import('@/views/user/general/General.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'general',
                            accountType: 'native',
                        },
                    },
                ],
            },
            {
                path: 'settings/user/edit',
                component: () => import('@/views/user/Edit.vue').catch(async () => onImportError()),
                beforeEnter: async (routeLocation) => {
                    const allowedUserEdition = Store.getters['user/allowedUserEdition'];
                    const me: User = Store.state.user.me;
                    if (allowedUserEdition || Number(routeLocation.params.id) === me.id) {
                        return await editBeforeRouteEnter(routeLocation, {
                            entityName: 'user',
                            payload: {
                                routeLocation,
                            },
                        });
                    } else {
                        return { name: 'user-list' };
                    }
                },
                children: [
                    {
                        name: 'user-edit',
                        path: 'general/:id',
                        component: () => import('@/views/user/general/General.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'user',
                            segment: 'general',
                        },
                    },
                    {
                        name: 'user-edit-notifications',
                        path: 'notifications/:id',
                        component: () => import('@/views/user/notifications/Notifications.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'user',
                            segment: 'notifications',
                        },
                    },
                    {
                        name: 'user-edit-accessibility',
                        path: 'accessibility/:id',
                        component: () => import('@/views/user/accessibility/Accessibility.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'user',
                            segment: 'accessibility',
                        },
                    },
                    {
                        name: 'user-edit-agenda',
                        path: 'agenda/:id',
                        component: () => import('@/views/user/agenda/Agenda.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'user',
                            segment: 'agenda',
                        },
                    },
                    {
                        name: 'user-edit-advanced',
                        path: 'advanced/:id',
                        component: () => import('@/views/user/advanced/Advanced.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'user',
                            segment: 'advanced',
                        },
                    },
                    {
                        name: 'user-edit-availability',
                        path: 'availability/:id',
                        component: () => import('@/views/user/availability/Availability.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'user',
                            segment: 'availabilities',
                        },
                    },
                ],
            },
            {
                name: 'group-list',
                path: 'settings/group/list',
                component: () => import('@/views/group/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'group-create',
                path: 'settings/group/edit',
                component: () => import('@/views/group/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'group' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'group-edit',
                path: 'settings/group/edit/:id',
                component: () => import('@/views/group/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'group' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'agency-list',
                path: 'settings/agency/list',
                component: () => import('@/views/agency/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'agency-create',
                path: 'settings/agency/edit',
                component: () => import('@/views/agency/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'agency' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'agency-edit',
                path: 'settings/agency/edit/:id',
                component: () => import('@/views/agency/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'agency' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'user-category-list',
                path: 'settings/user-category/list',
                component: () => import('@/views/userCategory/List.vue').catch(async () => onImportError()),
            },
            {
                name: 'user-category-create',
                path: 'settings/user-category/edit',
                component: () => import('@/views/userCategory/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'userCategory' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'user-category-edit',
                path: 'settings/user-category/edit/:id',
                component: () => import('@/views/userCategory/Edit.vue').catch(async () => onImportError()),
                meta: { entityName: 'userCategory' },
                beforeEnter: editBeforeRouteEnter,
            },
            {
                name: 'scheduled-meeting-tag-list',
                path: 'settings/scheduled-meeting-tag/list',
                component: () => import('@/views/scheduledMeetingTag/List.vue').catch(async () => onImportError()),
            },
            {
                path: 'settings/scheduled-meeting-tag/edit/',
                component: () => import('@/views/scheduledMeetingTag/Edit.vue').catch(async () => onImportError()),
                meta: {
                    entityName: 'scheduledMeetingTag',
                },
                beforeEnter: editBeforeRouteEnter,
                children: [
                    {
                        name: 'scheduled-meeting-tag-create',
                        path: 'general',
                        component: () => import('@/views/scheduledMeetingTag/edit/General.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'scheduledMeetingTag',
                            segment: 'general',
                        },
                    },
                    {
                        name: 'scheduled-meeting-tag-edit',
                        path: 'general/:id',
                        component: () => import('@/views/scheduledMeetingTag/edit/General.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'scheduledMeetingTag',
                            segment: 'general',
                        },
                    },
                    {
                        name: 'scheduled-meeting-tag-edit-meetings',
                        path: 'meetings/:id',
                        component: () => import('@/views/scheduledMeetingTag/edit/Meetings.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'scheduledMeetingTag',
                            segment: 'meetings',
                        },
                    },
                ],
            },
            // Statistics
            {
                path: 'reporting',
                redirect: '/main/reporting/book/meeting',
            },
            {
                path: 'reporting/book',
                component: () => import('@/views/reporting/book/Reporting.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'reporting-meeting',
                        path: 'meeting',
                        component: () => import('@/views/reporting/book/Meeting.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-book',
                            segment: 'meeting',
                        },
                        async beforeEnter() {
                            if (!Store.getters['user/hasApendayBookEnabled']) {
                                return {
                                    name: Store.getters['user/hasApendayLiveEnabled'] ? 'reporting-interaction' : 'reporting-session',
                                };
                            }
                        },
                    },
                    {
                        name: 'reporting-consultant',
                        path: 'consultant',
                        component: () => import('@/views/reporting/book/Consultant.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-book',
                            segment: 'consultant',
                        },
                    },
                    {
                        name: 'reporting-scheduler',
                        path: 'scheduler',
                        component: () => import('@/views/reporting/book/Scheduler.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-book',
                            segment: 'scheduler',
                        },
                    },
                    {
                        name: 'reporting-agency',
                        path: 'agency',
                        component: () => import('@/views/reporting/book/Agency.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-book',
                            segment: 'agency',
                        },
                    },
                    {
                        name: 'reporting-satisfaction',
                        path: 'satisfaction',
                        component: () => import('@/views/reporting/book/Satisfaction.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-book',
                            segment: 'satisfaction',
                        },
                    },
                ],
            },
            {
                path: 'reporting/live',
                component: () => import('@/views/reporting/interactionSession/Reporting.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'reporting-interaction',
                        path: 'interaction',
                        component: () =>
                            import('@/views/reporting/interactionSession/InteractionSession.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-interaction',
                            entityName: 'interaction',
                            segment: 'interaction',
                        },
                    },
                    {
                        name: 'reporting-interaction-consultant',
                        path: 'consultant',
                        component: () => import('@/views/reporting/interactionSession/Consultant.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-interaction',
                            entityName: 'interaction',
                            segment: 'consultant',
                        },
                    },
                    {
                        name: 'reporting-interaction-satisfaction',
                        path: 'satisfaction',
                        component: () => import('@/views/reporting/interactionSession/Satisfaction.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-interaction',
                            entityName: 'interaction',
                            segment: 'satisfaction',
                        },
                    },
                ],
            },
            {
                path: 'reporting/assist',
                component: () => import('@/views/reporting/interactionSession/Reporting.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'reporting-session',
                        path: 'session',
                        component: () =>
                            import('@/views/reporting/interactionSession/InteractionSession.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-session',
                            entityName: 'session',
                            segment: 'session',
                        },
                    },
                    {
                        name: 'reporting-session-consultant',
                        path: 'consultant',
                        component: () => import('@/views/reporting/interactionSession/Consultant.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-session',
                            entityName: 'session',
                            segment: 'consultant',
                        },
                    },
                    {
                        name: 'reporting-session-satisfaction',
                        path: 'satisfaction',
                        component: () => import('@/views/reporting/interactionSession/Satisfaction.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'reporting-session',
                            entityName: 'session',
                            segment: 'satisfaction',
                        },
                    },
                ],
            },

            // Ticketing
            {
                name: 'support-thread-list',
                path: 'support-thread/list',
                component: () => import('@/views/supportThread/List.vue').catch(async () => onImportError()),
                alias: ['support-thread/edit/:id?'],
            },
            // Notifications
            {
                name: 'app-event-list',
                path: 'app-event/list',
                component: () => import('@/views/appEvent/List.vue').catch(async () => onImportError()),
            },
            // Live
            {
                path: 'live',
                redirect: '/main/live/dashboard',
            },
            {
                name: 'live',
                path: 'live/dashboard',
                component: () => import('@/views/interaction/Dashboard.vue').catch(async () => onImportError()),
                beforeEnter() {
                    if (Store.getters['user/isGranted']['admin'] && !Store.getters['user/hasApendayLiveEnabled']) {
                        return { name: 'widget-list' };
                    }
                },
            },
            {
                name: 'widget-list',
                path: 'live/widget/list',
                component: () => import('@/views/widget/List.vue').catch(async () => onImportError()),
                meta: {
                    type: 'pending',
                    groupId: 'pending',
                },
            },
            {
                path: 'live/widget/edit/',
                component: () => import('@/views/widget/Edit.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'widget-create',
                        path: 'configuration',
                        component: () => import('@/views/widget/edit/config/Configuration.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'configuration',
                        },
                    },
                    {
                        name: 'widget-edit',
                        path: 'configuration/:id',
                        component: () => import('@/views/widget/edit/config/Configuration.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'widget',
                            segment: 'configuration',
                        },
                    },
                    {
                        name: 'widget-edit-dynamic-fields',
                        path: 'dynamic-fields/:id',
                        component: () => import('@/views/meeting/edit/dynamicFields/DynamicFields.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'widget',
                            segment: 'dynamic-fields',
                        },
                    },
                    {
                        name: 'widget-edit-team',
                        path: 'team/:id',
                        component: () => import('@/views/widget/edit/team/Team.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'widget',
                            segment: 'team',
                        },
                    },
                    {
                        name: 'widget-edit-appearance',
                        path: 'appearance/:id',
                        component: () => import('@/views/widget/edit/appearance/Appearance.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'widget',
                            segment: 'appearance',
                        },
                    },
                    // {
                    //     name: 'widget-edit-bot',
                    //     path: 'bot/:id',
                    //     component: () => import('@/views/widget/edit/bot/Bot.vue').catch(async () => onImportError()),
                    //     meta: {
                    //         groupId: 'widget',
                    //         segment: 'bot',
                    //     },
                    // },
                    {
                        name: 'widget-edit-satisfaction',
                        path: 'satisfaction/:id?',
                        component: () => import('@/views/meeting/edit/satisfaction/Satisfaction.vue').catch(async () => onImportError()),
                        meta: {
                            groupId: 'widget',
                            segment: 'satisfaction',
                        },
                    },
                ],
                beforeEnter: async (routeLocation) => {
                    return await editBeforeRouteEnter(routeLocation, {
                        entityName: 'widget',
                        callback: (route, item: Widget) => {
                            if (!item?.id || item?.permissions?.edit) {
                                return route;
                            } else {
                                return { name: 'widget-list' };
                            }
                        },
                    });
                },
            },
            {
                path: 'live/widget/publish/',
                component: () => import('@/views/widget/Publish.vue').catch(async () => onImportError()),
                children: [
                    {
                        name: 'widget-publish',
                        path: 'informations/:id',
                        component: () => import('@/views/widget/publish/informations/Informations.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'informations',
                        },
                    },
                    {
                        name: 'widget-publish-integration',
                        path: 'integration/:id',
                        component: () => import('@/views/widget/publish/integration/Integration.vue').catch(async () => onImportError()),
                        meta: {
                            segment: 'integration',
                        },
                    },
                ],
                beforeEnter: async (routeLocation) => {
                    return await editBeforeRouteEnter(routeLocation, {
                        entityName: 'widget',
                    });
                },
            },
            {
                name: 'interaction-pending',
                path: 'live/interaction/pending',
                component: () => import('@/views/interaction/List.vue').catch(async () => onImportError()),
                meta: {
                    type: 'pending',
                    groupId: 'pending',
                },
            },
            {
                name: 'interaction-in-progress',
                path: 'live/interaction/in-progress',
                component: () => import('@/views/interaction/List.vue').catch(async () => onImportError()),
                meta: {
                    type: 'inProgress',
                    groupId: 'inProgress',
                },
            },
            {
                name: 'interaction-closed',
                path: 'live/interaction/closed',
                component: () => import('@/views/interaction/List.vue').catch(async () => onImportError()),
                meta: {
                    type: 'closed',
                    groupId: 'closed',
                },
            },
            {
                path: 'session',
                redirect: '/main/session/dashboard',
            },
            {
                name: 'session',
                path: 'session/dashboard',
                component: () => import('@/views/session/Dashboard.vue').catch(async () => onImportError()),
                beforeEnter() {
                    if (Store.getters['user/isGranted']['admin'] && !Store.getters['user/hasApendayAssistEnabled']) {
                        return { name: 'session-in-progress' };
                    }
                },
            },
            {
                name: 'session-in-progress',
                path: 'session/in-progress',
                component: () => import('@/views/session/List.vue').catch(async () => onImportError()),
                meta: {
                    type: 'inProgress',
                    groupId: 'inProgress',
                },
            },
            {
                name: 'session-closed',
                path: 'session/closed',
                component: () => import('@/views/session/List.vue').catch(async () => onImportError()),
                meta: {
                    type: 'closed',
                    groupId: 'closed',
                },
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    loader.start();
    if (isInMaintenance) {
        next(to.name !== 'maintenance' ? { name: 'maintenance' } : null);
        return;
    }
    if (!isInMaintenance && to.name === 'maintenance') {
        next({ name: 'dashboard' });
        return;
    }

    const isEditPage = to.name?.toString().includes('-edit');
    const wasEditPage = from.name?.toString().includes('-edit');
    const wasCreatePage = from.name?.toString().includes('-create');
    const prevID = from.params.id;
    const nextID = to.params.id;

    if (to.name !== 'logout') await Store.dispatch('user/userGuard');
    if (
        to.name === 'reset-password' ||
        to.name === 'change-password' ||
        to.name === 'auth-microsoft' ||
        to.name === 'auth-google' ||
        to.name === 'logout'
    )
        next();
    else if (to.name !== 'login' && !Store.getters['user/isAuthenticated']) next({ name: 'login' });
    else if (to.name === 'login' && Store.getters['user/isAuthenticated']) next({ name: 'dashboard' });
    else if (isEditPage && (wasEditPage || wasCreatePage) && prevID !== nextID) {
        // force getting entity when we are already in an edit page.
        const entityName = camelCase(to.name.toString().split('-edit')[0]);
        await Store.dispatch(`${entityName}/get${upperFirst(entityName)}`, {
            id: to.params.id,
        });
        next();
    } else next();
});

router.beforeEach(async (to, from, next) => {
    const area = localStorage.getItem('apenday_active_area');
    if (area && !(to.query.area || to.query.areas || to.query['meeting.area'] || to.query['widget.area'])) {
        if (['scheduled-meeting-history', 'scheduled-meeting-past', 'scheduled-meeting-forthcoming'].includes(to.name)) {
            to.query = { ...to.query, 'meeting.area': area };
        } else if (['interaction-pending', 'interaction-in-progress', 'interaction-closed'].includes(to.name)) {
            to.query = { ...to.query, 'widget.area': area };
        } else if (
            [
                'widget-list',
                'meeting-list',
                'hub-list',
                'session-in-progress',
                'session-closed',
                'group-list',
                'agency-list',
                'scheduled-meeting-tag-list',
                'reporting-interaction',
                'reporting-session',
                'reporting-meeting',
                'reporting-consultant',
                'reporting-agency',
                'reporting-satisfaction',
                'reporting-interaction-satisfaction',
                'reporting-session-satisfaction',
            ].includes(to.name)
        ) {
            to.query = { ...to.query, area };
        } else if (['user-list'].includes(to.name)) {
            to.query = { ...to.query, areas: area };
        } else {
            next();
            return;
        }
        next(to);
        return;
    }
    next();
});

router.afterEach((to) => {
    loader.finish();
    Store.dispatch('navigation/syncLvlNavigation', { to });
});

router.onError(async () => {
    loader.finish();
    return '/';
});

export default router;
